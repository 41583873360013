import { TableRow, TableCell } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DoctreenButton from "../globals/Button";
import { useDispatch } from "react-redux";
import { setReporting } from "../../state/reporting";
import { formatDate } from "../../tools/dateFormat";
import { Reporting } from "../../state/reporting/types";
import Favicon from '../../assets/images/favicon.png'
import { removeReporting } from "../../state/reportingList/thunkActions";

interface OwnProps {
  document: Reporting,
  index: number,
}


const DocumentRow: FC<OwnProps> = ({ document, index }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const openReporting = () => {
    dispatch(setReporting({ reporting: document }));
    user_id && navigate('/user/' + user_id + '/documents/' + document.id)
  }

  const remove = (e: any) => {
    e.preventDefault();
    const confirm = window.confirm('Supprimer le document ?');
    if (confirm && document.id && user_id) {
      dispatch(removeReporting({ documentId: document.id, patientId: user_id }))
    }
  }

  return (
    <TableRow key={document.id} sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#e0e0e0' }} onContextMenu={remove}>
      <TableCell><img src={Favicon} alt="Logo_doctreen" className="logo-simple"></img></TableCell>
      <TableCell>{document.title}</TableCell>
      <TableCell>{formatDate(document.date)}</TableCell>
      <TableCell>{t('doctor')}: {document.author}</TableCell>
      <TableCell><DoctreenButton text={t('open')} action={() => { openReporting() }} /></TableCell>
    </TableRow>
  );
}

export default DocumentRow;