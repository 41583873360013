/** @format */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorPayloadType, GlobalErrorType } from '../errorHandler';
import { User } from '../user/types';
import { UserListSuccessType } from './types';
import server from '../../tools/server';
import { clearAuth } from '../auth';

// // import { clearUser } from '../user';
// // import { clearAdmin } from '../admin';

// /** ----------------------------ASYNC THUNK---------------------- */

export const search = createAsyncThunk<
    {
        success: UserListSuccessType;
        list: User[];
    },
    {},
    { rejectValue: ErrorPayloadType }
>('userList/search', async ({ }, thunkAPI: any) => {
    try {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: 'Bearer ' + thunkAPI.getState().auth.token,
            },
        };
        const connexion = await fetch(
            server + '/patients',
            requestOptions,
        );
        if (connexion.status === 500) {
            throw new Error('SEVER_ERROR');
        }
        if (connexion.status === 401) {
            thunkAPI.dispatch(clearAuth());
            return thunkAPI.rejectWithValue({ type: GlobalErrorType.UNAUTHORIZED });
        }

        const connexion2 = await fetch(
            server + '/examens',
            requestOptions,
        );
        if (connexion2.status === 500) {
            throw new Error('SEVER_ERROR');
        }
        if (connexion2.status === 401) {
            thunkAPI.dispatch(clearAuth());
            return thunkAPI.rejectWithValue({ type: GlobalErrorType.UNAUTHORIZED });
        }

        const body = await connexion.json();
        const examens = await connexion2.json();
        const users: User[] = [];
        body.data.forEach((row: any) => {
            const examensFind = row.examens.map((examen_id: string) => {
                const examenInfo = examens.data.find((exam: any) => exam._id === examen_id);
                if (examenInfo) {
                    return {
                        ...examenInfo,
                        id: examenInfo._id
                    }
                }
            })
            if (examensFind) {
                users.push({
                    ...row,
                    id: row._id,
                    documents: [],
                    examens: examensFind,
                });
            }
        });
        return {
            success: UserListSuccessType.USER_FETCHED,
            list: users,
        };
    } catch (error: any) {
        console.log({ error });
        return thunkAPI.rejectWithValue({ type: error });
    }
});

export const removeDocuments = createAsyncThunk<
    {
        success: UserListSuccessType;
    },
    {
        patientId: string,
    },
    { rejectValue: ErrorPayloadType }
>('userList/removeDocuments', async ({ patientId }, thunkAPI: any) => {
    try {
        const requestOptions: RequestInit = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: 'Bearer ' + thunkAPI.getState().auth.token,
            },
        };
        const connexion = await fetch(
            server + '/docs/patient/' + patientId,
            requestOptions,
        );
        if (connexion.status === 500) {
            throw new Error('SEVER_ERROR');
        }
        if (connexion.status === 401) {
            thunkAPI.dispatch(clearAuth);
            throw new Error('UNAUTHORIZED');
        }
        const body = await connexion.json();
        return {
            success: UserListSuccessType.DOCUMENTS_REMOVED
        };
    } catch (error: any) {
        console.log({ error });
        return thunkAPI.rejectWithValue(error);
    }
});