import examenToTree from "../UserList/examenToTree";

interface ReportInfo {
  tree: string;
  report: string;
}

const getRapportsPrecedents = (examenId: string, treeId: string): ReportInfo[] | null => {
  const examenDetails = examenToTree[examenId];
  if (!examenDetails) return null;
  const rapports: ReportInfo[] = [];

  switch (treeId) {
    case examenDetails.synthese?.tree:
      examenDetails.synthese && rapports.push(examenDetails.synthese);
      break;
    case examenDetails.questionnaire?.tree:
      examenDetails.synthese && rapports.push(examenDetails.synthese);
      examenDetails.questionnaire && rapports.push(examenDetails.questionnaire);
      break;
    case examenDetails.protocole?.tree:
      examenDetails.synthese && rapports.push(examenDetails.synthese);
      examenDetails.questionnaire && rapports.push(examenDetails.questionnaire);
      examenDetails.protocole && rapports.push(examenDetails.protocole);
      break;
    case examenDetails.technique?.tree:
      examenDetails.synthese && rapports.push(examenDetails.synthese);
      examenDetails.questionnaire && rapports.push(examenDetails.questionnaire);
      examenDetails.protocole && rapports.push(examenDetails.protocole);
      examenDetails.technique && rapports.push(examenDetails.technique);
      break;
    case examenDetails.report?.tree:
      examenDetails.synthese && rapports.push(examenDetails.synthese);
      examenDetails.questionnaire && rapports.push(examenDetails.questionnaire);
      examenDetails.protocole && rapports.push(examenDetails.protocole);
      examenDetails.technique && rapports.push(examenDetails.technique);
      examenDetails.report && rapports.push(examenDetails.report);
      break;
    default:
      break;
  }

  return rapports;
};

export default getRapportsPrecedents;
