import moment from 'moment';
import 'moment/locale/fr';
import i18n from './i18n';

export const formatDateOfBirth = (timestamp: string) => {
  moment.locale(i18n.language);
  const dateOfBirth = moment.unix(Date.parse(timestamp) / 1000);
  const formattedDate = dateOfBirth.format('DD/MM/YYYY');
  const age = moment().diff(dateOfBirth, 'years');
  return `${formattedDate} (${age} ans)`;
}

export const formatDate = (timestamp: string) => {
  moment.locale(i18n.language);
  const dateOfBirth = moment.unix(Date.parse(timestamp) / 1000);
  const formattedDate = dateOfBirth.format('DD/MM/YYYY');
  return `${formattedDate}`;
}


export const formatDateExtends = (timestamp: string) => {
  moment.locale(i18n.language);
  const dateOfBirth = moment.unix(Date.parse(timestamp) / 1000);
  const formattedDate = dateOfBirth.format('D MMMM YYYY');
  return `${formattedDate}`;
}
