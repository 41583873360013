/** @format */
import { createSlice } from '@reduxjs/toolkit';
import {
    isFulfilledAction,
    isPendingAction,
    isRejectedAction,
} from '../eventHelper';
import { ReportingListState } from './types';
import { documents } from '../../components/Documents/documents';
import { getReportings, removeReporting } from './thunkActions';

const initialState: ReportingListState = {
    list: documents,
    loading: false,
    success: undefined,
    error: undefined,
};

const reportingListSlice = createSlice({
    name: 'reportingList',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = undefined;
        },
        clearSuccess: (state) => {
            state.success = undefined;
        },
        clearState: (state) => {
            state.list = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReportings.fulfilled, (state, action) => {
                state.list = action.payload.list;
            })
            .addCase(removeReporting.fulfilled, (state, action) => {
                state.list = state.list.filter((reporting) => reporting.id !== action.meta.arg.documentId);
            })
            .addMatcher(isPendingAction('reportingList/'), (state) => {
                state.loading = true;
                state.success = undefined;
                state.error = undefined;
            })
            .addMatcher(isFulfilledAction('reportingList/'), (state, action) => {
                state.loading = false;
                state.error = undefined;
                state.success = action.payload.success;
            })
            .addMatcher(isRejectedAction('reportingList/'), (state, action) => {
                state.loading = false;
                state.success = undefined;
                state.error = action.payload;
            });
    },
});

const reportingListReducer = reportingListSlice.reducer;

export const { clearError, clearSuccess, clearState } = reportingListSlice.actions;

export default reportingListReducer;
