

import { AuthErrorType } from './auth/types';

export enum GlobalErrorType {
    NETWORK_ERROR = 'NETWORK_ERROR',
    UNAUTHORIZED = 'UNAUTHORIZED'
}

export type ErrorType = GlobalErrorType | AuthErrorType;

export type ErrorPayloadType = {
    type: ErrorType;
    silent?: boolean;
};
