import { CKEditor } from "@ckeditor/ckeditor5-react";
//@ts-ignore
import MyCustomEditors from '@ckeditor/ckeditor5-custom-build/build/custom_ck_editor';
// IF YOU ADD A NEW LANGUAGE YOU SHAVE TO IMPORT THE TRANSLATION FILE
//@ts-ignore
import '@ckeditor/ckeditor5-custom-build/build/translations/fr';
import { FC, SetStateAction, useState } from "react";
import i18n from "../../tools/i18n";

interface OwnProps {
  data: any;
  setData: React.Dispatch<SetStateAction<any>>;
}

const CKEditorComponent: FC<OwnProps> = ({ data, setData }) => {
  const language = i18n.language;

  return (
    <div id={`editor`} className="custom-ck-editor ck-content">
      <CKEditor
        key={language}
        editor={language === "fr" ? MyCustomEditors.HTMLEditorFR : MyCustomEditors.HTMLEditorEN}
        data={data}
        onChange={(_: any, editor: any) => setData(editor.getData())}
      />
    </div>
  );
}

export default CKEditorComponent;