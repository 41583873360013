import { FC, MouseEvent } from "react";
import { Button } from "react-bootstrap";

type OwnProps = {
  text?: string,
  action: () => void,
  className?: string
}

const DoctreenButton: FC<OwnProps> = ({ text, action, className }) => {
  return <Button className={`doctreen-button ${className}`} onClick={(e) => { e.stopPropagation(); action() }}>
    {text}
  </Button>
}

export default DoctreenButton;