
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    isFulfilledAction,
    isPendingAction,
    isRejectedAction,
} from '../eventHelper';
import { User, UserState } from './types';
import { documents } from '../../components/Documents/documents';
import { getRecents } from './thunkActions';

const initialState: UserState = {
    user: null,
    recentReporting: documents.slice(0, 5),
    loading: false,
    success: undefined,
    error: undefined,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state,
            action: PayloadAction<{
                user: User;
            }>
        ) => {
            state.user = action.payload.user
        },
        clearError: (state) => {
            state.error = undefined;
        },
        clearSuccess: (state) => {
            state.success = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRecents.fulfilled, (state, action) => {
                state.user = { ...state.user!, recentsReporting: action.payload.recents }
            })
            .addMatcher(isPendingAction('auth/'), (state) => {
                state.loading = true;
                state.success = undefined;
                state.error = undefined;
            })
            .addMatcher(isFulfilledAction('auth/'), (state, action) => {
                state.loading = false;
                state.error = undefined;
                state.success = action.payload.success;
            })
            .addMatcher(isRejectedAction('auth/'), (state, action) => {
                state.loading = false;
                state.success = undefined;
                state.error = action.payload;
            });
    },
});

const userReducer = userSlice.reducer;

export const { clearError, clearSuccess, setUser } = userSlice.actions;

export default userReducer;
