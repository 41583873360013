import { FC, useState } from "react";
import IframeModal from "./IFrameModal";
import CKEditorComponent from "./CKEditorComponent";
import PatientInfo from "./PatientInfo";
import { Grid } from "@mui/material";
import DoctreenButton from "../globals/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { saveReporting } from "../../state/reporting/thunkAction";
import { RootState } from "../../state/store";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const INITIAL_MESSAGE = `<div id='ckeditor_init'></div>`;


const Reporting: FC = () => {
  const [data, setData] = useState<string>(INITIAL_MESSAGE);
  const [reportingInfo, setReportingInfo] = useState<any>();
  const [open, setOpen] = useState(true);
  const [patientInfo, setPatientInfo] = useState(true);
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();
  const authorName = useSelector((state: RootState) => state.auth.name)


  const saveCR = (reportingInfo: any) => {
    const entete = document.getElementById('entete')?.innerHTML;
    const ckContent = document.getElementsByClassName('ck-content ck-editor__editable ')[0]?.innerHTML;
    const signature = document.getElementById('signature')?.innerHTML;
    const contentToSave = document.createElement('div');

    if (entete) {
      const enteteDiv = document.createElement('div');
      enteteDiv.innerHTML = entete;
      contentToSave.appendChild(enteteDiv);
    }

    if (ckContent) {
      const ckContentDiv = document.createElement('div');
      ckContentDiv.innerHTML = ckContent;
      contentToSave.appendChild(ckContentDiv);
    }

    if (signature) {
      const signatureDiv = document.createElement('div');
      signatureDiv.innerHTML = signature;
      contentToSave.appendChild(signatureDiv);
    }
    const text = contentToSave.outerHTML
    // TODO DEFINE VALUES
    user && dispatch(saveReporting({
      title: reportingInfo && reportingInfo.title || 'Compte rendu',
      author: authorName,
      data: text,
      patient_id: user.id,
      examenId: user.examens[0].id,
      reportId: reportingInfo && reportingInfo.reportId || '',
      reportingId: reportingInfo && reportingInfo.reportingId || '',
      treeId: reportingInfo && reportingInfo.treeId || '',
      reportingSourceIds: []
    }));
    setTimeout(() => {
      user && navigate('/user/' + user.id)
    }, 100)

  }

  return (
    <Grid container>
      <Grid className="grayed" item xs={patientInfo ? 3 : 1}>
        <PatientInfo open={patientInfo} setOpen={setPatientInfo} />
      </Grid >
      <Grid
        item
        xs={patientInfo ? 9 : 11}>
        {open ? <div className="cr-iframe"><IframeModal open={open} setOpen={setOpen} setData={setData} setReportingInfo={setReportingInfo} saveCR={saveCR} /> </div> :
          (<div className="cr-view">
            <div className="d-flex w-100 flex-column" id="reporting-content">
              <div id="entete">
                <Header />
              </div>
              <CKEditorComponent data={data} setData={setData} />
              <div id="signature">
                <Footer />
              </div>
            </div>
            <div className="d-flex w-100 justify-content-end mt-2">
              <DoctreenButton text={t("finish")} action={() => saveCR(reportingInfo)} className="right" />
            </div>
          </div>
          )
        }
      </Grid>
    </Grid>
  );
}

export default Reporting;