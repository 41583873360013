import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { formatDateExtends } from "../../tools/dateFormat";
import LogoSantexpo from '../../assets/images/santexpo.png'
import Caduce from '../../assets/images/caduce.svg'

const Header: FC = () => {
  const auth = useSelector((state: RootState) => state.auth);
  return <div className="background-white">
    <div className="d-flex justify-content-around flex-row align-items-center position-relative reporting-header" >
      <img src={LogoSantexpo} alt="Logo_santexpo" className="logo-header logo-santexpo position-absolute logo-left"></img>
      <img src={Caduce} alt="Logo_tahiti" className="logo-caduce position-absolute "></img>
      <div className="d-flex justify-content-center flex-column align-items-center w-33" >
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center w-33 text-center">
        <h2>ÉTABLISSEMENT DE SANTÉ</h2>
        <h5>912 RUE DE LA CROIX VERTE, 34080 MONTPELLIER</h5>
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center w-33" >
        <span>
          {formatDateExtends((new Date).toISOString())}
        </span>
      </div>
    </div>
  </div>;
}

export default Header;