import React from 'react';
import './modal.css';

export interface ModalProps {
  active: boolean,
  className?: string
  children: React.ReactElement | React.ReactElement[],
  closeModal?: () => void,
}

export const Modal = ({active, className, children, closeModal}: ModalProps) => active ? (
  <div className={`Modal ${className}`}>
    <div className="Modal-background" onClick={closeModal} />
    <div className="Modal-content d-flex col justify-between">
      {children}
    </div>
  </div>
) : null;

export interface ModalHeaderProps {
  className?: string
  children: React.ReactElement | React.ReactElement[] | React.ReactNode,
}
export const ModalHeader = ({className, children}: ModalHeaderProps) => (
  <div className={`ModalHeader ${className}`}>
    {children}
  </div>
);
export interface ModalBodyProps {
  className?: string
  children: React.ReactElement | React.ReactElement[] | React.ReactNode,
}
export const ModalBody = ({ className, children }: ModalBodyProps) => (
  <div className={`ModalBody ${className}`}>
    {children}
  </div>
);
export interface ModalFooterProps {
  className?: string
  children: React.ReactElement | React.ReactElement[] | React.ReactNode,
}
export const ModalFooter = ({ className, children }: ModalFooterProps) => (
  <div className={`ModalFooter ${className}`}>
    {children}
  </div>
);