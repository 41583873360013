
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    isFulfilledAction,
    isPendingAction,
    isRejectedAction,
} from '../eventHelper';
import { Reporting, ReportingState } from './types';
import { getReporting } from './thunkAction';


const initialState: ReportingState = {
    reporting: null,
    loading: false,
    success: undefined,
    error: undefined,
};

const reportingSlice = createSlice({
    name: 'reporting',
    initialState,
    reducers: {
        setReporting: (state,
            action: PayloadAction<{
                reporting: Reporting;
            }>
        ) => {
            state.reporting = action.payload.reporting
        },
        clearError: (state) => {
            state.error = undefined;
        },
        clearSuccess: (state) => {
            state.success = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReporting.fulfilled, (state, action) => {
                state.reporting = action.payload.currentDocument
            })
            .addMatcher(isPendingAction('reporting/'), (state) => {
                state.loading = true;
                state.success = undefined;
                state.error = undefined;
            })
            .addMatcher(isFulfilledAction('reporting/'), (state, action) => {
                state.loading = false;
                state.error = undefined;
                state.success = action.payload.success;
            })
            .addMatcher(isRejectedAction('reporting/'), (state, action) => {
                state.loading = false;
                state.success = undefined;
                state.error = action.payload;
            });
    },
});

const reportingReducer = reportingSlice.reducer;

export const { clearError, clearSuccess, setReporting } = reportingSlice.actions;

export default reportingReducer;
