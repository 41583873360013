import { TableContainer, Paper, TableBody, TableRow } from "@mui/material";
import { FC, useEffect } from "react";
import { Table } from "react-bootstrap";
import DocumentRow from "./DocumentRow";
import { useTranslation } from "react-i18next";
import { formatDateOfBirth } from "../../tools/dateFormat";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useParams } from "react-router-dom";
import { getReportings } from "../../state/reportingList/thunkActions";


const Documents: FC = () => {
  const { t } = useTranslation('translation');
  const { user_id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user_id) {
      dispatch(getReportings({ userId: user_id }))
    }
  }, [user_id]);

  const documents = useSelector((state: RootState) => state.reportingList.list);

  const user = useSelector((state: RootState) => state.user.user);
  return user && (
    <div className="page-content">
      <div className="page-view">
        <div>
          <div className="patient-info">
            <div>
              <span><b>{t('lastname')}: </b></span>
              <span>{user.lastname}</span>
            </div>
            <div>
              <span><b>{t('firstname')}: </b></span>
              <span>{user.firstname}</span>
            </div>
            <div>
              <span><b>{t('sex')}: </b></span>
              <span>{t(user.sex)}</span>
            </div>
            <div>
              <span><b>{t('date_of_birth')}: </b></span><br />
              <span>{formatDateOfBirth(user.date_of_birth)}</span>
            </div>
            <div>
              <span><b>{t('ipp')}: </b></span>
              <span>{user.ipp}</span>
            </div>
            <div>
              <span><b>{t('ins')}: </b></span>
              <span>{user.ins}</span>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {documents.length > 0 ? documents.map((document, index) => (
                  <DocumentRow document={document} index={index} key={document.id} />
                )) :
                  <TableRow>Aucun documents</TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default Documents;