

import React, { FC, Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
// @ts-ignore
import { PersistGate } from 'redux-persist/es/integration/react';
import './App.css';
import AppRouter from './components/routers/AppRouter';
import { persistor, store } from './state/store';
import { I18nextProvider } from 'react-i18next';
import i18n from './tools/i18n';

const App: FC = () => {

    return (
        <Suspense fallback='...Loading'>
            <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                    <PersistGate loading={null} persistor={persistor}>
                        <AppRouter />
                    </PersistGate>
                </I18nextProvider>
            </Provider >
        </Suspense>
    );
};

export default App;
