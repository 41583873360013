import { Reporting } from "../../state/reporting/types";

export const documents: Reporting[] = [
  // {
  //   id: "1",
  //   title: 'Document 1',
  //   date: 1677033600000, // 2023-02-22
  //   author: 'Jean Dupont',
  //   data: "<div><div><h2> UNE ENTÊTE</h2></div><div><div id='d11f4de5-bddf-4d29-87f3-d30d18a5e83b' class='DocumentMainRootNode'><h1><br data-cke-filler='true'></h1><p><strong><u>IRM VOIES BILIAIRES&nbsp;</u></strong><br><br><strong><u>INDICATION:</u></strong>&nbsp;<br><br><strong><u>TECHNIQUE</u></strong>&nbsp;<br>Séquences axiales T2, coronales T2, T1 in et out of phase, 3D EGT1 et diffusion.&nbsp;<br>après injection de gadobenate (Multihance); séries 3D EGT1 dynamique et passage tardif à 1 heure.&nbsp;<br><br><strong><u>RESULTAT</u></strong>&nbsp;<br>Pas de thrombose portale ou sus-hépatique.&nbsp;<br>Dilatation des voies biliaires intra-hépatiques () et du cholédoque () jusqu\'au niveau de la papille duodénale. On retrouve à ce niveau une lithiase en et mesurant.&nbsp;<br>Aspect d\'angiocholite avec infiltration de la graisse péri-cholédocienne et prise de contraste des parois du cholédoque.&nbsp;<br>Pas d\'anomalie de la vésicule biliaire.&nbsp;<br>Pancréas d\'aspect normal.&nbsp;<br><br><strong><u>CONCLUSION</u>&nbsp;</strong><br><strong>Aspect TDM d\'angiocholite typique sur lithiase de 0 mm localisée au niveau de la papille.&nbsp;</strong><br><br><br data-cke-filler='true'></p></div></div><div><h2> UNE SIGNATURE</h2></div></div>"
  // },
  // {
  //   id: "2",
  //   title: 'Document 2',
  //   date: 1676947200000, // 2023-02-21
  //   author: 'Marie Durand',
  //   data: "",
  // },
  // {
  //   id: "3",
  //   title: 'Document 3',
  //   date: 1676860800000, // 2023-02-20
  //   author: 'Pierre Martin',
  //   data: "",
  // },
  // {
  //   id: "4",
  //   title: 'Document 4',
  //   date: 1676774400000, // 2023-02-19
  //   author: 'Laura Bernard',
  //   data: "",
  // },
  // {
  //   id: "5",
  //   title: 'Document 5',
  //   date: 1676688000000, // 2023-02-18
  //   author: 'Paul Dubois',
  //   data: "",
  // },
  // {
  //   id: "6",
  //   title: 'Document 6',
  //   date: 1676601600000, // 2023-02-17
  //   author: 'Sophie Lefebvre',
  //   data: "",
  // },
  // {
  //   id: "7",
  //   title: 'Document 7',
  //   date: 1676515200000, // 2023-02-16
  //   author: 'François Moreau',
  //   data: "",
  // },
  // {
  //   id: "8",
  //   title: 'Document 8',
  //   date: 1676428800000, // 2023-02-15
  //   author: 'Anne-Sophie Davy',
  //   data: "",
  // },
  // {
  //   id: "9",
  //   title: 'Document 9',
  //   date: 1676342400000, // 2023-02-14
  //   author: 'Olivier Garnier',
  //   data: "",
  // },
  // {
  //   id: "10",
  //   title: 'Document 10',
  //   date: 1676256000000, // 2023-02-13
  //   author: 'Sarah Lemaire',
  //   data: "",
  // }
];