/** @format */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorPayloadType } from '../errorHandler';
import { UserSuccessType } from '../user/types';
import server from '../../tools/server';
import { Reporting } from '../reporting/types';
import { clearAuth } from '../auth';


// /** ----------------------------ASYNC THUNK---------------------- */

export const getRecents = createAsyncThunk<
    {
        success: UserSuccessType;
        recents: Reporting[];
    },
    {},
    { rejectValue: ErrorPayloadType }
>('user/getRecents', async ({ }, thunkAPI: any) => {
    const patientId = thunkAPI.getState().user.user.id;
    try {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: 'Bearer ' + thunkAPI.getState().auth.token,
            },
        };
        const connexion = await fetch(
            server + '/patients/' + patientId + '/documents/recent',
            requestOptions,
        );
        if (connexion.status === 500) {
            throw new Error('SEVER_ERROR');
        }
        if (connexion.status === 401) {
            thunkAPI.dispatch(clearAuth);
            throw new Error('UNAUTHORIZED');
        }


        const body = await connexion.json();
        const recentsReporting: Reporting[] = body.data.docs.map((doc: any) => {
            return {
                ...doc,
                id: doc._id,
            }
        })

        return {
            success: UserSuccessType.RECENTS_REPORTING_FETCHED,
            recents: recentsReporting,
        };
    } catch (error: any) {
        console.log({ error });
        return thunkAPI.rejectWithValue(error);
    }
});

