

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import auth from './auth'
import user from './user'
import usersList from './userList'
import reportingList from './reportingList'
import reporting from './reporting'

// Middleware: Redux Persist Config
const persistConfig = {
    // Root
    key: 'root',
    // Storage Method
    storage: storageSession,
    // Whitelist (Save Specific Reducers)
    whitelist: ['user', 'userList', 'reporting', 'reportingList', 'auth'],
    // Blacklist (Don't Save Specific Reducers)
    blacklist: [],
};

/**
 *
 * Add all reducer we want to use in the app
 *
 */
const rootReducer = combineReducers({
    auth,
    usersList,
    user,
    reporting,
    reportingList
});

/**
 *
 * Configuration of the persist reducer
 *
 */
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 *
 * Configuration of the store
 * Add custom middlewares
 *
 */
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        })
});

// Middleware: Redux Persist Persister
export let persistor = persistStore(store);

export type RootState = ReturnType<typeof persistedReducer>;

export type RootDispatch = typeof store.dispatch;
