

import React, { FunctionComponent } from 'react';

type Props = { loading: boolean };

const Spinner: FunctionComponent<Props> = (props) => {
    return props.loading ? (
        <div className="d-flex justify-content-center pb-3">
            <div className="spinner-border text-primary" role="status"></div>
        </div>
    ) : (
        <></>
    );
};

export default Spinner;
