import { ErrorType } from "../errorHandler";




export type Reporting = {
  id?: string;
  title: string;
  date: string;
  author: string;
  data: string;
}

export type ReportingState = {
  reporting: Reporting | null,
  loading?: boolean,
  success?: ReportingSuccessType,
  error?: ErrorType,
};


export enum ReportingSuccessType {
  REPORTING_FETCHED = "REPORTING_FETCHED",
  REPORTING_CREATED = "REPORTING_CREATED"
}

export enum ReportingErrorType { }