

import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../state/store';
import Spinner from './Spinner';
import { login } from '../../state/auth/thunkActions';

const Login: FunctionComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const auth: any = useSelector((state: RootState) => state.auth);
    const doLogin = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        dispatch(login({ email, password }));
    };

    return (
        <div>
            <form onSubmit={doLogin}>
                <div className="auth-wrapper">
                    <div className="auth-content">
                        <div className="auth-bg">
                            <span className="r"></span>
                            <span className="r s"></span>
                            <span className="r s"></span>
                            <span className="r"></span>
                        </div>
                        <div className="card card-border">
                            <div className="card-body text-center d-flex justify-content-center flex-column align-items-center">
                                <h3 className="mb-4">LOGIN</h3>
                                <div className="input-group mb-3 w-50">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder={'Email'}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="input-group mb-4 w-50">
                                    <input
                                        type="password"
                                        className="form-control"
                                        autoComplete={'autoComplete'}
                                        placeholder={'Password'}
                                        required
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        onKeyPress={(ev) => {
                                            if (ev.key === 'Enter') {
                                                doLogin(ev);
                                            }
                                        }}
                                    />
                                </div>
                                {auth.loading ? (
                                    <Spinner loading={auth.loading} />
                                ) : (
                                    <input
                                        className="btn btn-primary mb-4"
                                        type="submit"
                                        value={'Connexion'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Login;
