import React, { FC } from "react";
import CustomNavbar from "../Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import UserList from "../UserList/UserList";
import Reporting from "../Reporting/Reporting";
import UserRouter from "../routers/UserRouter";
import { USER_ROUTES } from "../../tools/constants";


const MainPage: FC = () => {
  return (<div className="main-page">
    <CustomNavbar />
    <Routes>
      <Route path={USER_ROUTES + "/*"} element={<UserRouter />} />
      <Route path="/reporting" element={<Reporting />} />
      <Route path="/reporting/:treeId" element={<Reporting />} />
      <Route path="/reporting/:treeId/:reportId" element={<Reporting />} />
      <Route path="/reporting/:treeId/:reportId/:technique" element={<Reporting />} />
      <Route path="*" element={<UserList />} />
    </Routes>
  </div>
  );
}

export default MainPage