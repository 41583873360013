/** @format */
import { createSlice } from '@reduxjs/toolkit';
import {
    isFulfilledAction,
    isPendingAction,
    isRejectedAction,
} from '../eventHelper';
import { search } from './thunkActions';
import { UserListState } from './types';

const initialState: UserListState = {
    list: [],
    loading: false,
    success: undefined,
    error: undefined,
};

const userListSlice = createSlice({
    name: 'userList',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = undefined;
        },
        clearSuccess: (state) => {
            state.success = undefined;
        },
        clearState: (state) => {
            state.list = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(search.fulfilled, (state, action) => {
                state.list = action.payload.list
            })
            .addMatcher(isPendingAction('userList/'), (state) => {
                state.loading = true;
                state.success = undefined;
                state.error = undefined;
            })
            .addMatcher(isFulfilledAction('userList/'), (state, action) => {
                state.loading = false;
                state.error = undefined;
                state.success = action.payload.success;
            })
            .addMatcher(isRejectedAction('userList/'), (state, action) => {
                state.loading = false;
                state.success = undefined;
                state.error = action.error;
            });
    },
});

const userListReducer = userListSlice.reducer;

export const { clearError, clearSuccess, clearState } = userListSlice.actions;

export default userListReducer;
