import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { FC, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserRow from "./UserRow";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { search } from "../../state/userList/thunkActions";


const radilogyFilter = ['Radiologie', 'Scanner', 'IRM'];

const UserList: FC = () => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(search({}));
  }, [])
  const isRadiologue = useSelector((state: RootState) => state.auth.isRadiologue);
  const users = useSelector((state: RootState) => state.usersList.list.filter((user) => {
    if (isRadiologue) {
      return radilogyFilter.find((filter) => user.examens[0] && filter === user.examens[0].functional_unit);
    }
    return !radilogyFilter.find((filter) => user.examens[0] && filter === user.examens[0].functional_unit);
  }))
  return (
    <div className="page-content">
      <div className="page-view">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('lastname', { ns: 'translation' })}</TableCell>
                <TableCell>{t('firstname', { ns: 'translation' })}</TableCell>
                <TableCell>{t('sex', { ns: 'translation' })}</TableCell>
                <TableCell>{t('date_of_birth', { ns: 'translation' })}</TableCell>
                <TableCell>{t('ins', { ns: 'translation' })}</TableCell>
                <TableCell>{t('ipp', { ns: 'translation' })}</TableCell>
                <TableCell>{t('functional_unit', { ns: 'translation' })}</TableCell>
                <TableCell>{t('examen_motif', { ns: 'translation' })}</TableCell>
                {isRadiologue ? null : <TableCell>{t('motif', { ns: 'translation' })}</TableCell>}
                {isRadiologue ? <TableCell>{t('pre-admission', { ns: 'translation' })}</TableCell> : null}
                {isRadiologue ? <TableCell>{t('quoting', { ns: 'translation' })}</TableCell> : <TableCell>{t('synthese_patient', { ns: 'translation' })}</TableCell>}
                {isRadiologue ? <TableCell>{t('modality', { ns: 'translation' })}</TableCell> : null}
                {isRadiologue ? <TableCell>{t('consultation', { ns: 'translation' })}</TableCell> : <TableCell>{t('document', { ns: 'translation' })}</TableCell>}

              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <UserRow user={user} index={index} key={user.ins} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default UserList;