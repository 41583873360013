const replaceAll = (string: string, search: string | RegExp, replace: string) => {
  return string.split(search).join(replace);
};

const replaceSpan = (match: string) => {
  return replaceAll(match, "span", "figure");
};

export default (): string => {
  const documentHTML = document.getElementById('printContent');
  if (documentHTML) {
    const cloned = documentHTML.cloneNode(true) as Element;
    const spansWithImageClass = cloned.querySelectorAll('.image, .image-inline'); // ou 'image-inline' selon votre besoin

    Array.from(spansWithImageClass).forEach((currentSpan: any) => {
      const divsInsideSpan = currentSpan.getElementsByTagName('div');
      if (currentSpan.classList.contains('image-style-side')) {
        currentSpan.style.float = 'right';
      } else if (currentSpan.classList.contains('image')) {
        currentSpan.style.margin = '0 auto';
        currentSpan.style.display = 'block';
      }
      Array.from(divsInsideSpan).forEach((div: any) => {
        if (div.parentNode) {
          div.parentNode.removeChild(div);
        }
      });
    });

    const text = cloned.innerHTML;
    const regexRule = /<span class="image.*?<\/span>/gs;
    let generateText = text.replace(regexRule, (match) => replaceSpan(match));
    return generateText;
  }
  return ''
};

