
import { createAsyncThunk } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { ErrorPayloadType } from '../errorHandler';
import { RootState } from '../store';
import { AuthSuccessType, AuthErrorType } from './types';
import server from '../../tools/server';


// /** ----------------------------ASYNC THUNK---------------------- */

export const login = createAsyncThunk<
    { success: AuthSuccessType; id: number; isRadiologue: boolean; token: string, name: string, refreshToken: string, firstname: string },
    { email: string; password: string },
    { rejectValue: ErrorPayloadType }
>('auth/login', async ({ email, password }, thunkAPI) => {
    try {
        const bodyContent = JSON.stringify({
            email: email,
            password: password,
        });

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            body: bodyContent,
        };
        const connexion = await fetch(server + '/auth/login', requestOptions);
        if (connexion.status === 500) {
            throw new Error('SEVER_ERROR');
        }

        const body = await connexion.json();
        const decode: any = jwtDecode(body.accessToken);
        sessionStorage.setItem('token', body.refreshToken);
        return {
            success: AuthSuccessType.LOGGED_IN,
            id: decode.id,
            name: decode.lastname,
            firstname: body.medecin.firstname,
            isRadiologue: decode.job === 'Radiologue',
            token: body.accessToken,
            refreshToken: body.refreshToken,
        };
    } catch (error: any) {
        if (error.status === 403) {
            return thunkAPI.rejectWithValue({
                type: AuthErrorType.NOT_ALLOWED,
            });
        }
        return thunkAPI.rejectWithValue({
            type: AuthErrorType.BAD_CREDENTIALS,
        });
    }
});

export const logout = createAsyncThunk<
    { success: AuthSuccessType },
    { cache?: boolean },
    { state: RootState; rejectValue: ErrorPayloadType }
>('auth/logout', async ({ cache = false }, thunkAPI) => {
    if (!cache) {
        thunkAPI.dispatch(clearState());
    }
    try {
        sessionStorage.removeItem('token');
        const bodyContent = JSON.stringify({
            refreshToken: thunkAPI.getState().auth.refreshToken,
        });
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            body: bodyContent
        };
        const connexion = await fetch(server + '/auth/logout', requestOptions);
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
    return { success: AuthSuccessType.LOGGED_OUT };
});

export const clearState = createAsyncThunk<
    { success: AuthSuccessType },
    undefined,
    { state: RootState }
>('auth/logout', async () => {
    return { success: AuthSuccessType.CLEARED_STATE };
});

export const retrieveCheckToken = createAsyncThunk<
    { success: AuthSuccessType; id: number; isRadiologue: boolean; token: string },
    undefined,
    { state: RootState; rejectValue: ErrorPayloadType }
>('auth/retrieveCheckToken', async (_, thunkAPI) => {
    let retrievedToken: string | null;
    try {
        retrievedToken = await sessionStorage.getItem('token');
    } catch (err) {
        thunkAPI.dispatch(clearState());
        return thunkAPI.rejectWithValue({
            type: AuthErrorType.SECURE_STORE_UNAVAILABLE,
        });
    }
    if (!retrievedToken) {
        thunkAPI.dispatch(clearState());
        return thunkAPI.rejectWithValue({
            type: AuthErrorType.TOKEN_NOT_FOUND,
            silent: true,
        });
    }
    // Ckecking the token
    const decode: any = jwtDecode(retrievedToken);
    const userid = decode.id;
    let isRadiologue = false;
    if (decode.exp && decode.exp > Date.now()) {
        return thunkAPI.rejectWithValue({
            type: AuthErrorType.INVALID_TOKEN,
            silent: true,
        });
    }
    if (decode.isAdmin) {
        isRadiologue = true;
    }
    if (userid)
        return {
            success: AuthSuccessType.LOGGED_IN,
            id: userid,
            isRadiologue,
            token: retrievedToken,
        };

    // Refreshing the token while it is still valid
    return {
        success: AuthSuccessType.LOGGED_IN,
        id: userid,
        isRadiologue,
        token: retrievedToken,
    };
});
