import { Grid, Paper } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useNavigate, useParams } from "react-router-dom";
import { getReporting } from "../../state/reporting/thunkAction";
import DoctreenButton from "../globals/Button";
import formatHtmlToPdf from "./formatHtmlToPdf";
// @ts-ignore
import html2pdf from 'html2pdf.js';
import SendForm from "./SendForm";
import { useTranslation } from "react-i18next";
import PatientInfo from "../Reporting/PatientInfo";


const Document: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);
  const [pdf, setPdf] = useState<any>(null);

  const reporting = useSelector((state: RootState) => state.reporting.reporting);
  const { user_id, document_id } = useParams();
  const users = useSelector((state: RootState) => state.usersList.list);
  const user = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (document_id) {
      dispatch(getReporting({ documentId: document_id }))
    }
  }, [document_id]);


  const print = () => {
    const generateText = formatHtmlToPdf();
    const pdfName = 'CR_DOCTEEN.pdf';
    try {
      html2pdf().from(generateText).set({
        margin: [25, 15, 15, 15],
        filename: pdfName,
        image: { type: 'png', quality: 1.0 },
        html2canvas: { dpi: 75, scale: 2, letterRendering: true },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        jsPDF: { orientation: 'portrait', unit: 'mm', format: 'a4', compressPDF: true },
      })
        .toPdf()
        .get('pdf').then(function (pdf: any) {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
          }
          pdf.autoPrint();
          window.open(pdf.output('bloburl'), '_blank');
        });
    } catch (error) {
      console.error(error);
    }
  }

  const send = () => {
    const generateText = formatHtmlToPdf();
    const pdfName = 'CR_DOCTEEN.pdf';
    try {
      html2pdf().from(generateText).set({
        margin: [25, 15, 15, 15],
        filename: pdfName,
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        jsPDF: { orientation: 'portrait', unit: 'mm', format: 'a4', compress: true },
      })
        .toPdf()
        .get('pdf').then(async function (pdf: any) {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
          }
          pdf.autoPrint();
          let blob = await pdf.output('blob');
          blob.originalname = pdfName;
          setPdf(blob); // res is base64 now
          setOpen(true);
        });
    } catch (error) {
      console.error(error);
    }
  }

  const [patientInfo, setPatientInfo] = useState(true);


  return (
    <Grid container>
      <Grid className="grayed" item xs={patientInfo ? 3 : 1}>
        <PatientInfo open={patientInfo} setOpen={setPatientInfo} />
      </Grid >
      <Grid
        item
        xs={patientInfo ? 9 : 11}>
        <div className="page-content page-center flex-column ">
          <div className="d-flex justify-content-between w-25">
            {user && <DoctreenButton action={() => navigate('/user/' + user_id)} text={`${user?.lastname} ${user?.firstname}`} />}
            {user && <DoctreenButton action={() => print()} text={t(`print`)} />}
            {user &&
              <DoctreenButton action={() => send()} text={t('send')} />
            }
          </div>
          <Paper id={'printContent'} elevation={4} className="page-document mt-2">
            <div dangerouslySetInnerHTML={{ __html: reporting ? reporting?.data : '' }} />
          </Paper>
          <SendForm open={open} setOpen={setOpen} pdf={pdf} />
        </div>
      </Grid>
    </Grid>

  );
}

export default Document;