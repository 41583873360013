/** @format */

import { ErrorPayloadType } from '../errorHandler';
import { User } from '../user/types';

export type UserListState = {
    list: User[];
    loading: boolean;
    success?: UserListSuccessType;
    error?: ErrorPayloadType;
};


export enum UserListSuccessType {
    USER_FETCHED = 'USER_FETCHED',
    USER_UPDATED = 'USER_UPDATED',
    USER_LIKE_REFRESH = "USER_LIKE_REFRESH",
    DOCUMENTS_REMOVED = "DOCUMENTS_REMOVED"
}

export enum UserListErrorType { }
