import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import LogoDoctreen from '../../assets/images/banniere_doctreen.png'
import QRLogo from '../../assets/images/code_qr.png'

const Footer: FC = () => {
  const auth = useSelector((state: RootState) => state.auth);
  return <div className="background-white small-text">
    <div className="d-flex justify-content-center flex-column align-items-center position-relative">
      <a href="https://doctreen.com" target="_blank">
        <img src={LogoDoctreen} alt="Logo_doctreen" className="logo-doctreen position-absolute logo-left"></img>
      </a>
      <img src={QRLogo} alt="Logo_doctreen" className="logo-qr position-absolute"></img>
      <div className="d-flex justify-content-end w-75 mt-4 mb-4">
        <div className="d-flex justify-content-center flex-column align-items-center author-sign">
          <h5>
            Dr {auth.name} {auth.fisrtname}
          </h5>
          <h6>
            {auth.isRadiologue ? 'Radiologue' : 'Médecin généraliste'}
          </h6>
        </div>
      </div>
      <div className="d-flex justify-content-center w-75">
        <div className="d-flex justify-content-center flex-column align-items-center text-center">
          <span>
            ABRAHAMIAN Thomas, Directeur Développement,
          </span>
          <span>
            <a href="mailto:thomas.abrahamian@doctreen.com"> thomas.abrahamian@doctreen.com</a>
            +33 6 34 47 00 00
          </span>
        </div>
      </div>
    </div>
  </div>;
}

export default Footer;