import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Documents from "../Documents/Documents";
import Document from "../Document/Document";


const UserRouter: FC = () => {

  return (
    <Routes>
      <Route path="/:user_id" element={<Documents />} />
      <Route path="/:user_id/documents/:document_id" element={<Document />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default UserRouter;
