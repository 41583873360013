import { ErrorPayloadType } from "../errorHandler";
import { Reporting } from "../reporting/types";

export type User = {
  id: string;
  firstname: string;
  lastname: string;
  sex: string;
  date_of_birth: string;
  ins: string;
  ipp: string;
  examens: Examen[];
  documents: Reporting[];
  recentsReporting?: Reporting[];
};

export type Examen = {
  id: string;
  functional_unit: string;
  quoting: string[];
  modality: string;
}

export type UserState = {
  user: User | null;
  recentReporting: Reporting[];
  loading: boolean;
  success?: UserSuccessType;
  error?: ErrorPayloadType;
};


export enum UserSuccessType {
  USER_FETCHED = 'USER_FETCHED',
  USER_UPDATED = 'USER_UPDATED',
  USER_LIKE_REFRESH = "USER_LIKE_REFRESH",
  RECENTS_REPORTING_FETCHED = "RECENTS_REPORTING_FETCHED"
}

export enum UserErrorType { }