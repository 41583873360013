import { FC, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from '../globals/Login';
import MainPage from '../MainPage/MainPage';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';

const AppRouter: FC = () => {
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    useEffect(() => {
    }, [isLoggedIn])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={isLoggedIn ? <Navigate to='/' replace /> : <Login />} />
                <Route path="*" element={isLoggedIn ? <MainPage /> : <Navigate to='/login' replace />} />
            </Routes>
        </BrowserRouter>
    );
};
{/* <Navigate to='/login' replace /> */ }
export default AppRouter;
