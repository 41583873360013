import { createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../tools/server";
import { ErrorPayloadType } from "../errorHandler";
import { Reporting, ReportingSuccessType } from "./types";
import { clearAuth } from "../auth";

export const getReporting = createAsyncThunk<
  {
    success: ReportingSuccessType;
    currentDocument: Reporting;
  },
  {
    documentId: string
  },
  { rejectValue: ErrorPayloadType }
>('reporting/get', async ({ documentId }, thunkAPI: any) => {
  try {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + thunkAPI.getState().auth.token,
      },
    };
    const connexion = await fetch(
      server + '/docs/' + documentId,
      requestOptions,
    );
    if (connexion.status === 500) {
      throw new Error('SEVER_ERROR');
    }
    if (connexion.status === 401) {
      thunkAPI.dispatch(clearAuth);
      throw new Error('UNAUTHORIZED');
    }
    const body = await connexion.json();
    const reporting = {
      id: body.data._id,
      title: body.data.title,
      date: body.data.timestamp,
      data: body.data.data,
      author: body.data.author,
    }

    return {
      success: ReportingSuccessType.REPORTING_FETCHED,
      currentDocument: reporting,
    };
  } catch (error: any) {
    console.log({ error });
    return thunkAPI.rejectWithValue(error);
  }
});



export const saveReporting = createAsyncThunk<
  {
    success: ReportingSuccessType;
  },
  {
    title: string;
    author: string;
    data: string;
    patient_id: string;
    treeId: string;
    reportId: string;
    examenId: string;
    reportingId: string;
    reportingSourceIds: string[]
  },
  { rejectValue: ErrorPayloadType }
>('reporting/create', async ({ title, author, data, patient_id, examenId, reportId, reportingId, reportingSourceIds, treeId }, thunkAPI: any) => {
  try {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + thunkAPI.getState().auth.token,
      },
      body: JSON.stringify({
        title: title,
        author: author,
        data: data,
        examenId,
        reportId,
        reportingId,
        reportingSourceIds,
        treeId,
      })
    };
    const connexion = await fetch(
      server + '/docs/patient/' + patient_id,
      requestOptions,
    );
    if (connexion.status === 500) {
      throw new Error('SEVER_ERROR');
    }
    if (connexion.status === 401) {
      thunkAPI.dispatch(clearAuth);
      throw new Error('UNAUTHORIZED');
    }
    return {
      success: ReportingSuccessType.REPORTING_CREATED,
    };
  } catch (error: any) {
    console.log({ error });
    return thunkAPI.rejectWithValue(error);
  }
});