import { FC, useEffect, useState } from 'react';
import { AppBar, Toolbar, } from '@mui/material';
import AppLogo from './AppLogo';
import i18n from '../../tools/i18n';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { logout } from '../../state/auth/thunkActions';

const CustomNavbar: FC = () => {
  const [language, setLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const toggleLanguage = () => {
    const newLanguage = language.startsWith("fr") ? 'en' : 'fr';
    setLanguage(newLanguage);
  };

  const userName = useSelector((state: RootState) => state.auth.name + ' ' + state.auth.fisrtname)
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const logoutUser = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(logout({}));
  }

  return (
    <AppBar position="static" color='default'>
      <Toolbar disableGutters>
        <div className='d-flex w-100 justify-content-between align-items-center mr-3 ml-3'>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <a href='/' className='no-style'>
              <h2 className='app_title'>
                SI METIER
              </h2>
            </a>
          </div>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <h6 onClick={toggleLanguage}>{language.startsWith("fr") ? t('fr') : t('en')}</h6>
            <h5 className='ml-5' onContextMenu={(e) => { logoutUser(e) }}>Dr {userName}</h5>
          </div>
        </div>
      </Toolbar>
    </AppBar >
  );
}

export default CustomNavbar;