/** @format */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorPayloadType } from '../errorHandler';
import { ReportingListSuccessType, } from './types';
import server from '../../tools/server';
import { Reporting } from '../reporting/types';
import { clearAuth } from '../auth';

// // import { clearUser } from '../user';
// // import { clearAdmin } from '../admin';

// /** ----------------------------ASYNC THUNK---------------------- */

export const getReportings = createAsyncThunk<
    {
        success: ReportingListSuccessType;
        list: Reporting[];
    },
    {
        userId: string
    },
    { rejectValue: ErrorPayloadType }
>('reportingList/get', async ({ userId }, thunkAPI: any) => {
    try {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: 'Bearer ' + thunkAPI.getState().auth.token,
            },
        };
        const connexion = await fetch(
            server + '/patients/' + userId + '/documents',
            requestOptions,
        );
        if (connexion.status === 500) {
            throw new Error('SEVER_ERROR');
        }
        if (connexion.status === 401) {
            thunkAPI.dispatch(clearAuth);
            throw new Error('UNAUTHORIZED');
          }
        const body = await connexion.json();
        const documents: Reporting[] = body.data.docs.map((doc: any) => {
            return {
                ...doc,
                id: doc._id,
            }
        })

        return {
            success: ReportingListSuccessType.REPORTINGS_FETCHED,
            list: documents,
        };
    } catch (error: any) {
        console.log({ error });
        return thunkAPI.rejectWithValue(error);
    }
});

export const removeReporting = createAsyncThunk<
  {
    success: ReportingListSuccessType;
  },
  {
    documentId: string,
    patientId: string,
  },
  { rejectValue: ErrorPayloadType }
>('reportingList/remove', async ({ documentId, patientId }, thunkAPI: any) => {
  try {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + thunkAPI.getState().auth.token,
      },
    };
    const connexion = await fetch(
      server + '/docs/' + documentId + '/patient/' + patientId,
      requestOptions,
    );
    if (connexion.status === 500) {
      throw new Error('SEVER_ERROR');
    }
    if (connexion.status === 401) {
      thunkAPI.dispatch(clearAuth);
      throw new Error('UNAUTHORIZED');
    }
    const body = await connexion.json();
    console.warn({ body });

    return {
      success: ReportingListSuccessType.REPORTING_REMOVED
    };
  } catch (error: any) {
    console.log({ error });
    return thunkAPI.rejectWithValue(error);
  }
});