import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import dayjs from 'dayjs';
import fr from '../assets/locales/fr/translation.json';
import en from '../assets/locales/en/translation.json';

const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];

i18n
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    initImmediate: false,
    // default fallback will be french
    fallbackLng: 'fr',
    // default namespace to load
    ns: 'translation',
    // debug only in dev
    debug: LOCAL_DOMAINS.includes(window.location.hostname),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
  });

i18n.on('initialized', () => {
  dayjs.locale(i18n.language);
});
i18n.on('languageChanged', () => {
  document.documentElement.lang = i18n.language;
  dayjs.locale(i18n.language);
});

export default i18n;
