
interface ExamenDetails {
  motif: string;
  synthese?: { tree: string; report: string };
  questionnaire?: { tree: string; report: string };
  protocole?: { tree: string; report: string };
  technique?: { tree: string; report: string };
  report?: { tree: string; report: string };
}

export interface ExamenToTree {
  [examenId: string]: ExamenDetails;
}

const examenToTree: ExamenToTree = {
  ["507f191e810c19729de860ec"]: {
    motif: 'Radiologie mammographie',
    synthese: { tree: "660ca1058df813330dc5802f", report: "660ca1058df813330dc58030" },
    questionnaire: { tree: "", report: "" },
    protocole: { tree: "", report: "" },
    technique: { tree: "662f24669bdb2a921c8dbe9d", report: "662f24669bdb2a921c8dbe9e" },
    report: { tree: "65a80f219f89a3314c030a9f", report: "" },
  },
  ["507f191e810c19729de860ed"]: {
    motif: 'Trauma crânien',
  },
  ["507f191e810c19729de860ee"]: {
    motif: 'Fièvre, douleur abdo, vomissement',
  },
  ["507f191e810c19729de860ef"]: {
    motif: 'Scanner cérébral',
    synthese: { tree: "", report: "" },
    questionnaire: { tree: "62f31121272c6f00155cc8c2", report: "" },
    protocole: { tree: "5f8eaea9f430e10013a84082", report: "" },
    technique: { tree: "5f8eaea9f430e10013a84082", report: "66297a489bdb2a921c5cfe8c" },
    report: { tree: "5d293fe1668d290013c2223e", report: "" },
  },
  ["507f191e810c19729de860f0"]: {
    motif: 'Scanner cérébral',
    synthese: { tree: "638b04aff5391a1c61ad014e", report: "63aced0ff5391a1c61b0bccc" },
    questionnaire: { tree: "62f31121272c6f00155cc8c2", report: "" },
    protocole: { tree: "5f8eaea9f430e10013a84082", report: "" },
    technique: { tree: "5f8eaea9f430e10013a84082", report: "66297a489bdb2a921c5cfe8c" },
    report: { tree: "5d293fe1668d290013c2223e", report: "" },
  },
  ["507f191e810c19729de860f1"]: {
    motif: 'Radiologie du bassin simple',
    synthese: { tree: "663ae4119bdb2a921c38cb75", report: "663ae4119bdb2a921c38cb76" },
    questionnaire: { tree: "", report: "" },
    protocole: { tree: "", report: "" },
    technique: { tree: "6630454b9bdb2a921caba8f0", report: "6630454b9bdb2a921caba8f1" },
    report: { tree: "654850583b60ae72a75bb3c7", report: "" },
  },
  ["507f191e810c19729de860f2"]: {
    motif: 'Dyspnée, douleur thoracieque, toux, certificat sportif',
  },
  ["507f191e810c19729de860f3"]: {
    motif: '1ère consultation',
  },
  ["507f191e810c19729de860f4"]: {
    motif: 'Irm cérébrale (AVC)',
    synthese: { tree: "638b04aff5391a1c61ad014e", report: "63aced0ff5391a1c61b0bccc" },
    questionnaire: { tree: "62f31121272c6f00155cc8c2", report: "" },
    protocole: { tree: "633851919ab0960014a13475", report: "" },
    technique: { tree: "633851919ab0960014a13475", report: "664b9b019bdb2a921c2acd90" },
    report: { tree: "633837979ab0960014a01eba", report: "" },
  },
  ["507f191e810c19729de860f5"]: {
    motif: 'Scanner abdomino-pelvien',
    synthese: { tree: "", report: "" },
    questionnaire: { tree: "60d1848a356930001c81eac5", report: "" },
    protocole: { tree: "5d294001668d290013c22b05", report: "" },
    technique: { tree: "5d294001668d290013c22b05", report: "662977979bdb2a921c5cd3d2" },
    report: { tree: "5ebf5999f3cfe60013adef53", report: "" },
  },
  ["507f191e810c19729de860f6"]: {
    motif: 'Radiographie poignet',
    synthese: { tree: "638b04aff5391a1c61ad014e", report: "63aced0ff5391a1c61b0bccc" },
    questionnaire: { tree: "60d1848a356930001c81eac5", report: "" },
    protocole: { tree: "5d294001668d290013c22b05", report: "" },
    technique: { tree: "5d294001668d290013c22b05", report: "662977979bdb2a921c5cd3d2" },
    report: { tree: "60d1848a356930001c81eac5", report: "" },
  }
}

export const DEFAULT_CASE = {
  motif: '',
  synthese: { tree: "", report: "" },
  questionnaire: { tree: "", report: "" },
  protocole: { tree: "", report: "" },
  technique: { tree: "", report: "" },
  report: { tree: "", report: "" },
}

export default examenToTree;