
import { createSlice } from '@reduxjs/toolkit';
import {
    isFulfilledAction,
    isPendingAction,
    isRejectedAction,
} from '../eventHelper';
import { login, logout, retrieveCheckToken } from './thunkActions';
import { AuthState } from './types';

const initialState: AuthState = {
    isLoggedIn: false,
    isRadiologue: false,
    id: undefined,
    name: '',
    token: '',
    fisrtname: '',
    refreshToken: '',
    loading: false,
    success: undefined,
    error: undefined,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = undefined;
        },
        clearSuccess: (state) => {
            state.success = undefined;
        },
        clearAuth: (state) => {
            state.isLoggedIn = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.name = action.payload.name;
                state.id = action.payload.id;
                state.isRadiologue = action.payload.isRadiologue;
                state.token = action.payload.token;
                state.refreshToken = action.payload.refreshToken;
                state.fisrtname = action.payload.firstname
            })
            .addCase(retrieveCheckToken.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.id = action.payload.id;
                state.isRadiologue = action.payload.isRadiologue;
                state.token = action.payload.token;
            })
            .addCase(logout.fulfilled, (state) => {
                state.isRadiologue = false;
                state.name = '';
                state.id = undefined;
                state.token = '';
                state.isLoggedIn = false;
            })
            .addMatcher(isPendingAction('auth/'), (state) => {
                state.loading = true;
                state.success = undefined;
                state.error = undefined;
            })
            .addMatcher(isFulfilledAction('auth/'), (state, action) => {
                state.loading = false;
                state.error = undefined;
                state.success = action.payload.success;
            })
            .addMatcher(isRejectedAction('auth/'), (state, action) => {
                state.loading = false;
                state.success = undefined;
                state.error = action.payload;
            });
    },
});

const authReducer = authSlice.reducer;

export const { clearError, clearSuccess, clearAuth } = authSlice.actions;

export default authReducer;
