

import { ErrorPayloadType } from '../errorHandler';

export type AuthState = {
    isLoggedIn: boolean;
    isRadiologue: boolean;
    id?: number;
    name: string,
    fisrtname: string,
    token: string;
    refreshToken: string;
    loading: boolean;
    success?: AuthSuccessType;
    error?: ErrorPayloadType;
};


export enum AuthSuccessType {
    CLEARED_STATE = 'CLEARED_STATE',
    LOGGED_IN = 'LOGGED_IN',
    LOGGED_OUT = 'LOGGED_OUT',
    REFRESHED_TOKEN = 'RESFRESHED_TOKEN',
    USER_CREATED = "USER_CREATED"
}

export enum AuthErrorType {
    BAD_CREDENTIALS = 'BAD_CREDENTIALS',
    EXPIRED_TOKEN = 'EXPIRED_TOKEN',
    INVALID_TOKEN = 'INVALID_TOKEN',
    NOT_LOGGED_IN = 'NOT_LOGGED_IN',
    SECURE_STORE_UNAVAILABLE = 'SECURE_STORE_UNAVAILABLE',
    TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
    NOT_ALLOWED = 'NOT_ALLOWED',
    AUTH_FAILED = "AUTH_FAILED",
    CONFLICT = "CONFLICT",
    FAILED_TO_REGISTER = "FAILED_TO_REGISTER"
}
