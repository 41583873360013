/** @format */

import { ErrorPayloadType } from '../errorHandler';
import { Reporting } from '../reporting/types';

export type ReportingListState = {
    list: Reporting[];
    loading: boolean;
    success?: ReportingListSuccessType;
    error?: ErrorPayloadType;
};


export enum ReportingListSuccessType {
    REPORTINGS_FETCHED = "REPORTINGS_FETCHED",
    REPORTING_REMOVED = "REPORTING_REMOVED"
}

export enum ReportingListErrorType { }
