import { FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import DoctreenButton from "../globals/Button";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../tools/Modal/Modal";
import { FormControl, FormControlLabel, OutlinedInput, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import server from "../../tools/server";

interface OwnProps {
  open: boolean,
  setOpen: React.Dispatch<SetStateAction<boolean>>,
  pdf: any,
}

const SendForm: FC<OwnProps> = ({ open, setOpen, pdf }) => {

  const [sexe, setSexe] = useState('female');
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [institution, setInstitution] = useState('');
  const [job, setJob] = useState('');
  const { t } = useTranslation('translation');

  const sendMail = async () => {
    try {
      const formData = new FormData();
      formData.append('pdf', pdf, `${firstname}_${lastname}.pdf`);
      // @ts-ignore
      const requestOptions: RequestInit = {
        method: 'POST',
        body: formData,
      };
      const connexion = await fetch(server + '/mailing/upload-pdf', requestOptions);
      if (connexion.status === 500) {
        throw new Error('SEVER_ERROR');
      }
      if (connexion.status === 200) {
        const bodyContent = JSON.stringify({
          sexe,
          lastname,
          firstname,
          mail,
          phone,
          institution,
          job,
        });
        const requestOptions2: RequestInit = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json; charset=UTF-8' },
          body: bodyContent,
        };
        const connexion2 = await fetch(server + '/mailing/send', requestOptions2);
        if (connexion2.status === 500) {
          throw new Error('SEVER_ERROR');
        }
        setOpen(false);
      }
    } catch (error: any) {

      console.error('FAILED', { error })
    }
  }

  const handleSexeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSexe(event.target.value);
  };

  return (
    <>
      <Modal active={open} className="ModalPreview" closeModal={() => setOpen(false)}>
        <ModalHeader>
          <h2>Envoie du CR</h2>
        </ModalHeader>
        <ModalBody className="d-flex flex-column w-50 justify-content-around ">
          <FormControl >
            <Typography variant="body1" gutterBottom>
              Choix du sexe:
            </Typography>
            <RadioGroup
              defaultValue={sexe}
              onChange={handleSexeChange}
              row
            >
              <FormControlLabel value="female" control={<Radio />} label={`${t('female')}`} />
              <FormControlLabel value="male" control={<Radio />} label={`${t('male')}`} />
              <FormControlLabel value="other" control={<Radio />} label={`${t('other')}`} />
            </RadioGroup>
          </FormControl>
          <FormControl >
            <TextField label={t('lastname')} placeholder={t('lastname')} type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} />
          </FormControl>
          <FormControl >
            <TextField label={t('firstname')} placeholder={t('firstname')} type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
          </FormControl>
          <FormControl >
            <TextField label={t('mail')} placeholder={t('mail')} type="email" required value={mail} onChange={(e) => setMail(e.target.value)} />
          </FormControl>
          <FormControl >
            <TextField label={t('phone')} placeholder={t('phone')} type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </FormControl>
          <FormControl >
            <TextField label={t('institution')} placeholder={t('institution')} type="text" value={institution} onChange={(e) => setInstitution(e.target.value)} />
          </FormControl>
          <FormControl >
            <TextField label={t('job')} placeholder={t('job')} type="text" value={job} onChange={(e) => setJob(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter className="no-border mt-3">
          <DoctreenButton action={() => sendMail()} text={t("send_form")} className="mt-3" />
        </ModalFooter>
      </Modal>
    </>
  );
}

export default SendForm;