import { TableRow, TableCell, Tooltip } from "@mui/material";
import { FC } from "react";
import DoctreenButton from "../globals/Button";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { formatDateOfBirth } from "../../tools/dateFormat";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { User } from "../../state/user/types";
import { setUser } from "../../state/user";
import { getReportings } from "../../state/reportingList/thunkActions";
import examenToTree, { DEFAULT_CASE } from "./examenToTree";
import { removeDocuments } from "../../state/userList/thunkActions";

interface OwnProps {
  user: User,
  index: number,
}

const MOTIF_TREE = '64ae123649ae9d9da25852be';
const MOTIF_REPORT = '64ae123649ae9d9da25852bf';

const UserRow: FC<OwnProps> = ({ user, index }) => {
  const isRadiologue = useSelector((state: RootState) => state.auth.isRadiologue)
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const setUserReport = () => {
    dispatch(setUser({ user }));
  }

  const createCR = (treeId?: string, reportId?: string, technique?: boolean) => {
    const formatUrl = treeId ? reportId ? `/${treeId}/${reportId}` : `/${treeId}` : '';
    setUserReport();
    navigate(`/reporting${formatUrl}${technique ? '/technique' : ''}`);
  }

  const getDocument = () => {
    setUserReport();
    user && dispatch(getReportings({ userId: user.id }))
    navigate('/user/' + user.id)
  }
  const examenId = user.examens[0].id;
  const details = examenToTree[examenId] || DEFAULT_CASE;

  const clearDocuments = (e: any) => {
    e.preventDefault();
    const confirm = window.confirm("Voulez-vous supprimer tout les documents du patient ?");
    if (confirm) {
      dispatch(removeDocuments({ patientId: user.id }))
    }
  }

  return (
    <TableRow key={user.ins} sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#e0e0e0' }} onClick={() => getDocument()} onContextMenu={(e) => clearDocuments(e)}>
      <TableCell> {user.lastname}</TableCell>
      <TableCell>{user.firstname}</TableCell>
      <TableCell>{t(user.sex)}</TableCell>
      <TableCell>{formatDateOfBirth(user.date_of_birth)}</TableCell>
      <TableCell>{user.ins}</TableCell>
      <TableCell>{user.ipp}</TableCell>
      <TableCell>{user.examens[0].functional_unit}</TableCell>
      <TableCell>{details.motif}</TableCell>
      {
        isRadiologue ? null
          :
          <TableCell >
            <DoctreenButton text={t('motif')} action={() => { createCR(MOTIF_TREE, MOTIF_REPORT) }} />
          </TableCell>

      }
      {
        isRadiologue ? <TableCell className="d-flex justify-content-center">
          <div className="d-flex flex-column synthese-motif">
            <DoctreenButton text={t('synthese')} action={() => { createCR(details.synthese?.tree, details.synthese?.report) }} />
            <DoctreenButton text={t('bon_demande')} action={() => { createCR(details.questionnaire?.tree, details.questionnaire?.report) }} />
          </div>
        </TableCell>
          :
          <TableCell >
            <DoctreenButton text={t('synthese_patient')} action={() => { createCR("638b04aff5391a1c61ad014e", "63aced0ff5391a1c61b0bccc") }} />
          </TableCell>
      }
      {
        isRadiologue ?
          user.examens[0].functional_unit === 'Radiologie' ?
            <TableCell >
              {user.examens[0].quoting}
              <br />
              <DoctreenButton text={'T'} action={() => { createCR(details.technique?.tree, details.technique?.report) }} className="mt-1" />
            </TableCell>
            :
            <TableCell>
              <div className="d-flex flex-column">
                {user.examens[0].quoting}
                <br />

                <div className="d-flex row justify-content-around mt-1">
                  <Tooltip title={t('protocole')}>
                    <span>
                      <DoctreenButton text={'P'} action={() => { createCR(details.protocole?.tree, details.protocole?.report) }} />
                    </span>
                  </Tooltip>
                  <Tooltip title={t('technique')}>
                    <span>
                      <DoctreenButton text={'T'} action={() => { createCR(details.technique?.tree, details.technique?.report, true) }} />
                    </span>
                  </Tooltip>
                </div>
              </div>
            </TableCell>
          :
          null

      }
      {isRadiologue ? <TableCell>{user.examens[0].modality}</TableCell> : null}
      <TableCell>
        {isRadiologue ? <DoctreenButton text={t('create_cr')} action={() => { createCR(details.report?.tree, details.report?.report) }} /> :
          examenId === "507f191e810c19729de860ed" ?
            <DoctreenButton text={t('bon_demande')} action={() => { createCR() }} />
            : examenId === "507f191e810c19729de860f3" ? null :
              <DoctreenButton text={t('certificat')} action={() => { createCR("5d65b58fe913240013fda5ab", "64939d6798ec297fc3b81884") }} className="mt-2" />
        }
      </TableCell>
    </TableRow >
  );
}

export default UserRow;