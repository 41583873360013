import { FC, SetStateAction, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { formatDate, formatDateOfBirth } from "../../tools/dateFormat";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Reporting } from "../../state/reporting/types";
import { getRecents } from "../../state/user/thunkActions";

interface OwnProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>
}

const PatientInfo: FC<OwnProps> = ({ open, setOpen }) => {
  const { t } = useTranslation('translation');
  const user = useSelector((state: RootState) => state.user.user);
  const reportings = useSelector((state: RootState) => state.user.user?.recentsReporting);
  const user_id = useSelector((state: RootState) => state.user.user?.id);
  // const isRadiologue = useSelector((state: RootState) => state.auth.isRadiologue);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.id) {
      dispatch(getRecents({}));
    }
  }, [user && user.id]);

  const openReporting = (reporting: Reporting) => {
    if (user_id) {
      const newViewUrl = '/user/' + user_id + '/documents/' + reporting.id;
      window.open(newViewUrl, '_blank');
    }
  }
  return (
    <Drawer
      open={true}
      variant='persistent'
      anchor='left'
      className={"drawer"}
      classes={{
        paper: 'paper'
      }}
    >
      <div className="position-absolute top-right mt-2">
        {
          open ?
            <Tooltip title={t('hide_preview')}>
              <Button className="doctreen-button" onClick={() => setOpen(false)}>
                <ArrowBack fontSize="large" />
              </Button>
            </Tooltip> :
            <Tooltip title={t('display_preview')}>
              <Button className="doctreen-button" onClick={() => setOpen(true)}>
                <ArrowForward color="inherit" fontSize="large" />
              </Button>
            </Tooltip>
        }
      </div>
      {
        open ?
          <div className="p-3">
            <h3>{t('user_info')}</h3>
            <div className="d-flex flex-wrap flex-column mt-5">
              <span><b>{t('lastname')}: </b>{user?.lastname}</span>
              <span><b>{t('firstname')}: </b>{user?.firstname}</span>
              <span><b>{t('sex')}: </b>{user && t(user.sex)}</span>
              <span><b>{t('date_of_birth')}: </b>{user && formatDateOfBirth(user.date_of_birth)}</span>
              <span><b>{t('ipp')}: </b>{user?.ipp}</span>
              <span><b>{t('ins')}: </b>{user?.ins}</span>
            </div>
            <Divider className="mt-2" />
            <div className="d-flex flex-wrap flex-column mt-2">
              <span><b>{t('modality')}: </b></span>
              <span>{user?.examens[0].modality}</span>
              <span><b>{t('functional_unit')}: </b></span>
              <span>{user?.examens[0].functional_unit}</span>
            </div>
            <Divider className="mt-2" />
            <div className="d-flex flex-wrap flex-column mt-2">
              <h4 className="mb-5">{t('last_documents')}: </h4>
              {reportings && reportings.map((reporting, index) => {
                return <div key={reporting.id} className="d-flex justify-content-between align-items-center p-2" style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#e0e0e0' }}>
                  <div>
                    <h5>{reporting.title}</h5>
                    <span>{formatDate(reporting.date)}</span>
                  </div>
                  <IconButton onClick={() => openReporting(reporting)}>
                    <OpenInNewIcon color="inherit" />
                  </IconButton>
                </div>
              })}
            </div>
          </div> : null
      }
    </Drawer>
  );
}


export default PatientInfo;